import $ from 'jquery';
import { addOptionsToSelect } from './dom';

const getCountiesApi = (stateId) => {
  return fetch(`/api/state/${stateId}/counties`)
    .then(resp => resp.json());
}

const stateSelectChange = (statesId, countiesId) => {
  let stateSelectObj = $(statesId);
  let countySelectObj = $(countiesId);

  if (stateSelectObj && countySelectObj) {
    stateSelectObj.on("change", () => {
      let stateId = stateSelectObj.val();
      if (stateId) {
        getCountiesApi(stateId).then(response => {
          let mappedResult = response.map(resp => ({value: resp.id, text: resp.name,}));
          addOptionsToSelect(countySelectObj, mappedResult);
        });
      } else {
        addOptionsToSelect(countySelectObj, []);
      }
    });
  }
}

const getValue = (id) => {
  return $(id).val();
}

const getTextFromSelect = (id) => {
  return $(`${id} option:selected`).text();
}

const getValueFromCheckBox = (id) => {
  return $(`${id}`).prop('checked');
}

export {
  stateSelectChange,
  getValue,
  getTextFromSelect,
  getValueFromCheckBox,
}

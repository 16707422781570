import '@popperjs/core';
import 'bootstrap';
import $ from 'jquery';
import { stateSelectChange, getTextFromSelect, getValue, getValueFromCheckBox } from "./view/form"
import { addButtonEvent } from './view/dom';
import { getPropertyAndSellType, getLocation, getPrice, getDetail, } from './app/business.logic';
import { initMap } from './app/map';

const indexBtnId = "#index-btn-success";

const formIds = {
  state: "#states",
  county: "#counties",
  houseType: "#house-types",
  sellType: "#sell-types",
  floorUse: "#floor-use",
  startPrice: "#intial-price",
  endPrice: "#final-price",
  rooms: "#rooms",
  bathrooms: "#bathrooms",
  parking: "#parking",
  levels: "#levels",
  washingArea: "#washing-area",
  roomService: "#service-room",
}

const getUrl = () => {
  const houseTypeValue = getTextFromSelect(formIds.houseType);
  const sellTypeValue = getTextFromSelect(formIds.sellType);
  const floorUseValue = getTextFromSelect(formIds.floorUse);
  const stateValue = getTextFromSelect(formIds.state);
  const countyValue = getTextFromSelect(formIds.county);
  const startPriceValue = getValue(formIds.startPrice);
  const endPriceValue = getValue(formIds.endPrice);
  const roomsValue = getValue(formIds.rooms);
  const bathroomsValue = getValue(formIds.bathrooms);
  const parkingValue = getValue(formIds.parking);
  const levelsValue = getValue(formIds.levels);
  const washingAreaValue = getValueFromCheckBox(formIds.washingArea);
  const roomServiceValue = getValueFromCheckBox(formIds.roomService);

  const propertyAndSellType = getPropertyAndSellType(houseTypeValue, sellTypeValue);
  const location = getLocation(stateValue, countyValue);
  const price = getPrice(startPriceValue, endPriceValue);
  const detail = getDetail(roomsValue, bathroomsValue, parkingValue, levelsValue, roomServiceValue, washingAreaValue, floorUseValue);

  let url = `/product/busqueda/${propertyAndSellType}/${location}/${price}`;

  if (detail) {
    url += `/${detail}`;
  }

  return url;
}

$ ( document ).ready(() => {
  window.detailUrl = (id, title) => {
    window.location = `/product/propiedad/${id}_${title.replaceAll(" ", "-").toLowerCase()}`;
  }

  //index section
  addButtonEvent((indexBtnId), () => {
    window.location = getUrl();
  });

  stateSelectChange(formIds.state, formIds.county);

  initMap();
});

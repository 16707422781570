import $ from 'jquery';

const getJqueryInstance = (element) => {
  let jqueryElement;
  if (typeof element == "string") {
    jqueryElement = $(element);
  } else if (element instanceof $) {
    jqueryElement = element;
  } else {
    throw new Error(`can't bind ${element} value beacuse it's not a String or a Jquery Object`);
  }

  return jqueryElement;
}

/**
 * Add a list of options to a select object
 * 
 * @param {JQuery|string} select jquery object or string selector (#selector)
 * @param {{value, text}} options list of OptionSelect ({value, text})
 */
const addOptionsToSelect = (select, options, removeOriginal = true) => {
  let jquerySelect = getJqueryInstance(select);

  if (removeOriginal) {
    jquerySelect.children().remove(); 
  }

  jquerySelect.append($("<option>", {value: "", text: "Todos"}));
  $.each(options, (_i, item) => {
    let jqueryItem = $("<option>", item);
    jquerySelect.append(jqueryItem);
  })
}

const addButtonEvent = (btn, callback, event = "click") => {
  let jqueryButton = getJqueryInstance(btn);

  if (jqueryButton) {
    jqueryButton.on(event, (ev) => {
      callback(ev);
    });
  }
}

export {
  addOptionsToSelect,
  addButtonEvent,
}

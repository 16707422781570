let getPropertyAndSellType = (houseType, sellType) => {
  houseType = houseType.toLowerCase().replaceAll(' ', '-');
  sellType = sellType.toLowerCase().replaceAll(' ', '-');

  return `${houseType}_en_${sellType}`;
}

let getLocation = (state, county) => {
  if (isValidText(state) == "" && isValidText(county) == "") {
    return "mexico";
  }

  state = state.toLowerCase().replaceAll(" ", "-");
  county = county.toLowerCase().replaceAll(" ", "-");

  if (isValidText(county) == "") {
    return state;
  }

  return `${state}_${county}`;
}

let getPrice = (from, to) => {
  if (!from && !to) {
    return "cualquier_precio";
  }

  if (from && !to) {
    return `desde_${from}`;
  }

  if (!from && to) {
    return `hasta_${to}`;
  }

  return `desde_${from}_hasta_${to}`;
}

let getDetail = (rooms, bathrooms, parking, levels, serviceRoom, washingRoom, floorUse) => {
  let values = [];

  if (rooms) {
    values.push(`cuartos_${rooms}`);
  }

  if (bathrooms) {
    values.push(`banos_${bathrooms}`);
  }

  if (parking) {
    values.push(`estacionamiento_${parking}`);
  }

  if (levels) {
    values.push(`pisos_${levels}`);
  }

  if (serviceRoom) {
    values.push(`cuarto_servicio`);
  }

  if (washingRoom) {
    values.push(`area_lavado`);
  }

  if (isValidText(floorUse)) {
    values.push(floorUse.toLowerCase());
  }

  return values.reduce((prev, curr) => {
    if (prev) {
      return `${prev}-${curr}`;
    } else {
      return curr;
    }
  }, "");
}

let isValidText = (text) => {
  const ntext = text.toLowerCase();
  if (ntext) {
    return (ntext != 'todo' && ntext != "todos");
  }

  return false;
}

export {
  getPropertyAndSellType,
  getLocation,
  getPrice,
  getDetail,
}

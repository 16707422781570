import $ from 'jquery';

let map;

async function initMap(id = "map", zoom = 12) {
  if (window.google) {
    const { Map } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
  
    const mapDiv = document.getElementById(id);
    const mapJQ = $(mapDiv);
    const lat = mapJQ.data("lat");
    const lng = mapJQ.data("lon");
    const productId = mapJQ.data("id");
    const myLatLng = { lat, lng };
  
    map = new Map(mapDiv, {
      center: myLatLng,
      zoom,
      mapId: productId,
    });
  
    new AdvancedMarkerElement({
      position: myLatLng,
      map,
    });
  }
}

export { 
  initMap,
};
